import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { filter } from 'lodash'
import { useViewportScroll, motion, useTransform } from 'framer-motion'
import { useMeasure } from 'react-use'
import { use100vh } from 'react-div-100vh'

import { parseACF, renderLink } from '../utils'
import { Layout, Features, Grid, Slider, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, button } from '../styles/global'

import { headerHeight } from '../components/Header/Header'

const Index = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [heroRef, { bottom }] = useMeasure()
    const { scrollY } = useViewportScroll()
    const opacity = useTransform(scrollY, [0, bottom * 0.8], [1, 0.35])
    const screenHeight = use100vh()
    // console.log(data)

    const renderHero = () => {
        if (!data.hero_image) return

        return (
            <HeroWrapper
                style={{
                    height: `calc(${screenHeight}px - ${headerHeight})`,
                }}
            >
                <Hero
                    ref={heroRef}
                    style={{
                        opacity
                    }}
                >
                    {data.hero_media_type === 'image' && data.hero_image && (
                        <Image
                            key={data.hero_image.id}
                            src={data.hero_image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage 
                                        image={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image>
                    )}
                    {data.hero_media_type === 'video' && data.hero_video && (
                        <Video
                            url={data.hero_video}
                            playing={true}
                            loop={true}
                            controls={false}
                            muted={true}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 0, controls: 0, modestbranding: 1 }
                                },
                            }}
                        />
                    )}
                    <Overlay />
                    {data.hero_text && (
                        <Container
                            className={'hero-container'}
                        >
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.hero_text,
                                }}
                            />
                        </Container>
                    )}
                    <Icon
                        src={require('../assets/images/icons/scroll-arrow.svg')}
                    />
                </Hero>
            </HeroWrapper>
        )
    }

    const renderProductRange = () => {
        return (
            <ProductRange>
                <Image
                    key={data.product_range_image.id}
                    src={data.product_range_image.sizes.medium2}
                >
                    {(src, loading) => {
                        return (
                            <BGImage 
                                image={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image>
                <Info>
                    {data.product_range_heading && (
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: data.product_range_heading,
                            }}
                        />
                    )}
                    {data.product_range_link && (
                        <Button>
                            {renderLink(data.product_range_link, 0)}
                        </Button>
                    )}
                </Info>
            </ProductRange>
        )
    }

    const renderProducts = products => {
        const items = products.map((item, i) => {
            return (
                <Product key={i} as={Link} to={`/product/${item.slug}`}>
                    <ProductContainer>
                        {item.acf.product_feature_image && (
                            <Image
                                key={item.acf.product_feature_image.sizes.medium2}
                                src={item.acf.product_feature_image.sizes.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage 
                                            image={src} 
                                            style={{opacity: loading ? 0 : 1}}  
                                        />
                                    )
                                }}
                            </Image>
                        )}
                        <Info>
                            {item.title && (
                                <Heading>{item.title}</Heading>
                            )}
                            <Button>
                                See Product
                            </Button>
                        </Info>
                    </ProductContainer>
                </Product>
            )
        })
        return (
            <Grid
                columns={2}
                items={items}
            />
        )
    }

    const renderProductSections = () => {
        if (!data.product_cats) return
        const items = data.product_cats.map((item, i) => {
            const products = filter(data.products, (o) => {
                return o.cats[0].slug === item.slug
            })

            return (
                <ProductCat key={i}>
                    <Heading>{item.title}</Heading>
                    {products && renderProducts(products)}
                </ProductCat>
            )
        })

        return (
            <Products>
                {items}
            </Products>
        )
    }

    const renderFeatures = () => {
        if (!data.features) return

        return (
            <Features
                bgColour={data?.features_background_colour}
                heading={data?.features_heading}
                features={data.features}
                link={data.features_include_link && data?.features_link}
            />
        )
    }

    const renderGallery = () => {
        return (
            <Gallery>
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.gallery_heading,
                    }}
                />
                <Slider
                    slides={data.gallery_images}
                />
            </Gallery>
        )
    }

    const renderGuides = () => {
        const items = data.product_guides_images.map((item, i) => {
            return (
                <Image
                    key={item.image.id}
                    src={item.image.sizes.medium2}
                >
                    {(src, loading) => {
                        return (
                            <LoadedImage
                                src={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image> 
            )
        })

        return (
            <Guides>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.product_guides_heading,
                        }}
                    />
                    <Grid
                        columns={3}
                        tabColumns={3}
                        items={items}
                    />
                    {data.product_guides_link && (
                        <Button>
                            {renderLink(data.product_guides_link, 0)}
                        </Button>
                    )}
                </Container>
            </Guides>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
        >
            {renderHero()}
            <Wrapper>
                <Container
                    style={{
                        marginTop: `calc(${screenHeight}px - ${headerHeight})`,
                        paddingTop: '9rem',
                    }}
                >
                    {renderProductRange()}
                    {renderProductSections()}
                </Container>
                <Block
                    layout={'stockists_block'}
                />
                {data.features && renderFeatures()}
                <Container>
                    {renderGallery()}
                </Container>
                {renderGuides()}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Button = styled.div``
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`
const Icon = styled.img``


// Layout

const Container = styled.div`
    ${container};
    ${padding};
`

const Wrapper = styled.div`
    ${tw`relative bg-white w-full`};
    z-index: 1;
`



// Hero

const Video = styled(ReactPlayer)`
    width: 100%!important;
    height: 100%!important;
`
const Overlay = styled.div``

const HeroWrapper = styled.div`
    ${tw`fixed inset-x-0 w-full bg-black`};
    top: ${headerHeight};
`

const Hero = styled(motion.div)`
    ${tw`absolute inset-0 w-full h-full`};

    ${Overlay} {
        ${tw`absolute inset-0 bg-black`};
        opacity: 0.3;
        z-index: 1;
    }

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
    }

    ${Video}, iframe, video {
        ${tw`absolute inset-0`};
        width: 100%!important;
        height: 100%!important;
        object-fit: cover;
    }

    ${Container} {
        ${tw`absolute inset-0 flex items-center text-center`};
        z-index: 2;
    }

    ${Heading} {
        ${tw`w-full font-medium`};
        font-size: 3rem;
        line-height: 1.2;
        color: white;
    }

    ${Icon} {
        z-index: 2;
        ${tw`absolute`};
        left: 50%;
        bottom: 2rem;
        width: 2rem;
        height: 2rem;
        transform: translateX(-50%);
    }
`


// Product Range

const ProductRange = styled.div`
    ${tw`relative`};
    height: 42rem;

    ${media.mob`
        height: 36rem;
    `}

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};

        ${media.mob`
            background-position: right;
        `}
    }

    ${Info} {
        ${tw`flex flex-col items-start w-full px-8 py-6`};
    }

    ${Heading}, ${Button} {
        z-index: 1;
    }

    ${Heading} {
        ${tw`w-full font-medium`};
        font-size: 3rem;
        line-height: 1.2;
        color: white;
    }

    ${Button} {
        ${tw`mt-6`};

        > * {
            ${button}
            font-size: 1rem;
            color: white;

            &:hover {
                ${tw`bg-green`};
            }
        }
    }
`


// Products

const ProductCat = styled.div``
const Product = styled.div``
const ProductContainer = styled.div``
const Products = styled.div`
    padding-top: 9rem;
    padding-bottom: 9rem;

    ${ProductCat} {
        ${tw`text-center`};

        > ${Heading} {
            padding-bottom: 5rem; 
            ${tw`font-medium`};
            font-size: 2rem;
            line-height: 1.2;
        }

        &:not(:first-child) > ${Heading} {
            padding-top: 7rem;
        }
    }

    ${Product} {
        ${tw`flex flex-col`};

        ${ProductContainer} {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 81%;
            overflow: hidden;
        }

        ${BGImage} {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
        }

        ${Info} {
            ${tw`absolute inset-0 flex flex-col justify-between items-center w-full h-full pt-10 pb-12 px-4`};

            ${Heading}, ${Button} {
                ${tw`relative`};
                z-index: 1;
                color: white;
            }

            ${Heading} {
                ${tw`font-medium`};
                font-size: 2rem;
            }

            ${Button} {
                margin-top: 4rem;
                ${button}
                ${tw`bg-green border-white text-white`};
                font-size: 1rem;

                &:hover {
                    ${tw`bg-white text-green`};
                }
            }
        }
    }
`


// Gallery

const Gallery = styled.div`
    ${tw`text-center`};

    ${Heading} {
        ${tw`font-medium`};
        font-size: 2rem;
        margin-bottom: 4rem;
    }
`


// Guides

const Guides = styled.div`
    ${tw`text-center`};
    margin-top: 9rem;
    padding-top: 9rem;
    padding-bottom: 9rem;
    background-color: #ECECEC;

    ${Container} {
        ${tw`flex flex-col items-center`};
    }

    ${media.mob`
        .grid-item:not(:first-child) {
            display: none;
        }
    `}

    ${Heading} {
        ${tw`font-medium`};
        font-size: 2rem;
        margin-bottom: 4rem;
    }

    ${Button} {
        margin-top: 5rem;

        > * {
            ${button}
            ${tw`bg-green`};
            font-size: 1rem;

            &:hover {
                ${tw`bg-white text-green`};
            }
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
